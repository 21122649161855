import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { appRoutes } from './routes'
import { isAllowed } from './isAllowed'

const useSubmenu = () => {
    const location = useLocation()
    const { partnerType, isManager, currentPartner, permissions } = useAuth()
    const [items, setItems] = useState([])
    const currentRole = (permissions.find((v) => v.entity_id === currentPartner?.Id) || permissions[0])?.role

    const pathname = location.pathname.split('/').slice(0, 2).join('/')
    const PartnerStatus = currentPartner?.PartnerStatus

    useEffect(() => {
        const parent = appRoutes.find((m) => {
            const { path, alias = [] } = m
            return pathname.startsWith(path) || alias.includes(pathname)
        })

        const result = []
        if (parent) {
            const { children = [] } = parent

            children.forEach((m) => {
                const {
                    path,
                    title,
                    comparator,
                    isHidden,
                    // include = [],
                    // role,
                    justLink,
                    statuses = [],
                    clickAction,
                    allow,
                } = m

                if (
                    !isHidden &&
                    isAllowed(partnerType, currentRole, allow) &&
                    (statuses.length === 0 || statuses.includes(PartnerStatus))
                ) {
                    result.push({ path, title, comparator, justLink, clickAction })
                }

                // let allowed = true
                // if (role && role.length > 0 && include.length > 0) {
                //     allowed = role.includes(Role)
                // }

                // if (
                //     allowed &&
                //     !isHidden &&
                //     (include.length === 0 || include.includes(partnerType)) &&
                //     (statuses.length === 0 || statuses.includes(PartnerStatus))
                // ) {
                //     result.push({ path, title, comparator, justLink, clickAction })
                // }
            })
        }

        // console.log('submenu', result)
        setItems(result)
    }, [partnerType, PartnerStatus, pathname, currentRole, isManager])

    return items
}

export default useSubmenu
