import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import { appRoutes } from './routes'
import { isAllowed } from './isAllowed'

const useHeaderItems = () => {
    const { partnerType, permissions, currentPartner } = useAuth()
    const [items, setItems] = useState([])

    const currentRole = (permissions.find((v) => v.entity_id === currentPartner?.Id) || permissions[0])?.role

    useEffect(() => {
        const result = []
        appRoutes.forEach((m) => {
            const { path, title, isHidden, allow = {} } = m

            if (!isHidden && isAllowed(partnerType, currentRole, allow)) {
                result.push({ path, title })
            }
        })
        // console.log('menu', result)
        setItems(result)
    }, [partnerType, currentRole])

    return items
}

export default useHeaderItems
