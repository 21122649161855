import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import { cleanup } from '../utils'
import { rootItem, fakeItems, appRoutes } from './routes'
import { isAllowed } from './isAllowed'

const AccessDenied = () => (
    <div className='centered m-3'>
        <div className='alert alert-danger h4' role='alert'>
            Access Denied
        </div>
    </div>
)

const getRouteItems = (items, partnerType, currentRole) => {
    const result = []
    items.forEach((m) => {
        const { path, index, element, children = [], justLink, allow } = m

        if (!justLink) {
            if (isAllowed(partnerType, currentRole, allow)) {
                const item = cleanup({ path, index, element: element })
                if (children.length > 0) item['children'] = getRouteItems(children, partnerType, currentRole)
                result.push(item)
            } else {
                const item = cleanup({ path, index, element: <AccessDenied /> })
                result.push(item)
            }

            // const theElement = include.length === 0 || include.includes(partnerType) ? element : <AccessDenied />
            // const item = cleanup({ path, index, element: theElement })
            // if (children.length > 0) item['children'] = getRouteItems(children, partnerType, currentRole)
            // result.push(item)
        }
    })
    return result
}

const useAppRoutes = () => {
    const { partnerType, permissions, currentPartner } = useAuth()
    const [items, setItems] = useState([rootItem, ...fakeItems])
    const currentRole = (permissions.find((v) => v.entity_id === currentPartner?.Id) || permissions[0])?.role

    useEffect(() => {
        const result = getRouteItems(appRoutes, partnerType, currentRole)
        setItems([rootItem, ...result])
        // console.log('routes', [rootItem, ...result])
    }, [partnerType, currentRole])

    return items
}

export default useAppRoutes
