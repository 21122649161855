export const isAllowed = (partnerType, currentRole, allow = {}) => {
    if (Object.keys(allow).length === 0) {
        return true
    } else {
        const partnerRoles = allow[partnerType]
        if (Array.isArray(partnerRoles)) {
            if (partnerRoles.length === 0 || partnerRoles.includes(currentRole)) {
                return true
            }
        }
    }

    return false
}
